/* eslint-disable */
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  DxScrolling,
  DxColumnChooser
} from 'devextreme-vue/data-grid'
import DxForm from 'devextreme-vue/form'
import DxTextBox from 'devextreme-vue/text-box'
import {
  deliveryPendingUseCase,
  receiptCheckingUseCase
} from '@/domain/usecase'
import {
  ref,
  onMounted,
  reactive,
  getCurrentInstance
} from 'vue'
import StatusDropdown from '@/views/home/receipt-checking/detail/StatusDropdown.vue'
import { useStore } from 'vuex'

export default {
  name: 'TableReceiptCheckingDetail',
  components: {
    // DxCheckBox,
    DxColumn,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxSearchPanel,
    DxDataGrid,
    DxScrolling,
    StatusDropdown,
    DxForm,
    DxTextBox,
    DxColumnChooser
  },
  props: ['jadwalId', 'dataHeader'],
  emits: ['reloadHeader', 'showDetailResi'],
  setup(props: any, { emit }: any) {
    const store = useStore()
    const dataSource = ref([]) as any
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd,
      $checkIsRefund
    } = app!.appContext.config.globalProperties
    const {
      jadwalId,
      dataHeader
    } = reactive(props)
    const refStatusPengecekan = ref()
    const autoExpandAll = ref(true)
    const sumTunai = ref(0)
    const sumNonTunai = ref(0)
    const totalReceived = ref(0)
    const dataChoiceDelivery = ref(null) as any
    const dataChoiceStatus = ref(null) as any
    const descriptionTableFinished = ref(null) as any
    const isShowModalDesc = ref(false)
    const valueDescription = ref(null)
    const dataStatusPengecekanResi = ref([])
    const exceptionStatusId = ref([3,14,15])
    const getAllTable = async () => {
      const response = await deliveryPendingUseCase.getAll({
        expand: '&$expand=Manifest($select=NoManifest,Id,TransJadwalHdId), AgenAsal($select=Id,Nama), AgenTujuan($select=Id,Nama), JenisPembayaran($select=Kode,Nama,Id), StatusPengecekan($select=Id,Kode,Status,Warna,Icon,CanChange)',
        select: '&$select=Resi,Guid,JenisPembayaranId,KategoriBarang,TipePengiriman,Id,KeteranganPembayaran,TransJadwalHdId,GrandTotal,StatusPengecekanId,AgenAsalId,AgenTujuanId,KeteranganCheckScan,NamaPengirim,CheckById,CheckedBy,NamaPenerima,HpPengirim,HpPenerima,KomisiAgen,KomisiKru, StatusPengirimanId',
        filter: `TransJadwalHdId eq ${jadwalId} and StatusPengirimanId ne null`,
        // filter: `Manifestid ne null and TransJadwalHdId eq ${jadwalId}`,
        // top: 10,
        // skip: 0
      })
      dataSource.value = response.result.map((x: any) => {
        return {
          Id: x.Id,
          Agent: x.AgenAsal.Nama,
          Manifest: x.Manifest ? x.Manifest.NoManifest : '-',
          Resi: x.Resi,
          Tunai: x.JenisPembayaran.Kode.toLowerCase() === 'tunai' ? !exceptionStatusId.value.includes(x.StatusPengirimanId) ? `Rp. ${Number(x.GrandTotal).toLocaleString('id-ID')}` : '-' : '-',
          NonTunai: x.JenisPembayaran.Kode.toLowerCase() !== 'tunai' ? !exceptionStatusId.value.includes(x.StatusPengirimanId) ? `Rp. ${Number(x.GrandTotal).toLocaleString('id-ID')}` : '-' : '-',
          NumTunai: x.JenisPembayaran.Kode.toLowerCase() === 'tunai' ? x.GrandTotal : 0,
          NumNonTunai: x.JenisPembayaran.Kode.toLowerCase() !== 'tunai' ? x.GrandTotal : 0,
          Keterangan: x.KeteranganPembayaran,
          StatusPengecekan: x.StatusPengecekanId ? x.StatusPengecekan : '-',
          StatusPengecekanId: x.StatusPengecekanId,
          NamaStatusPengecekan: x.StatusPengecekanId ? x.StatusPengecekan.Status : '-',
          CheckById: x.CheckById,
          // NamaChecking: x.CheckById ? x.CheckBy.NamaLengkap : '-',
          NamaChecking: x.CheckById ? x.CheckedBy : '-',
          NamaPengirim: x.NamaPengirim,
          AgenAsal: x.AgenAsal,
          NamaAgenAsal: x.AgenAsalId ? x.AgenAsal.Nama : '-',
          AgenTujuan: x.AgenTujuan,
          NamaAgenTujuan: x.AgenTujuanId ? x.AgenTujuan.Nama : '-',
          KeteranganCheckScan: x.KeteranganCheckScan,
          NamaPenerima: x.NamaPenerima,
          HpPengirim: x.HpPengirim,
          HpPenerima: x.HpPenerima,
          KomisiAgen: `Rp. ${Number(x.KomisiAgen).toLocaleString('id-ID')}`,
          KomisiKru: `Rp. ${Number(x.KomisiKru).toLocaleString('id-ID')}`,
          StatusPengiriman: x.StatusPengirimanId,
        }
      })
      sumTunai.value = 0
      sumNonTunai.value = 0
      totalReceived.value = 0
      dataSource.value.forEach((element: any) => {
        if (!exceptionStatusId.value.includes(element.StatusPengiriman)) {
          sumTunai.value += element.NumTunai
          sumNonTunai.value += element.NumNonTunai
        }
      })
      if (dataHeader.IsClose) {
        totalReceived.value = dataHeader.UangDiterima
        descriptionTableFinished.value = dataHeader.KeteranganClose
      } else {
        totalReceived.value = sumTunai.value + sumNonTunai.value
      }
    }
    const fetchStatusPengecekanResi = async () => {
      const response = await receiptCheckingUseCase.getPengecekanResi({
        select: '&$select=Id,Kode,Status,Warna,Icon,CanChange',
        custom: 'IsActive eq true'
      })
      if (!response.error) {
        dataStatusPengecekanResi.value = response.result
      }
    }

    const showPanelStatus = async (data: any, evt: any) => {
      if (!dataHeader.IsClose) {
        // if (data.StatusPengecekan.CanChange === true) {
        dataChoiceDelivery.value = await data
        refStatusPengecekan.value.toggle(evt)
        // }
      }
    }

    const choiceStatusFromDropDown = async (val: any) => {
      dataChoiceStatus.value = await val
      isShowModalDesc.value = true
      refStatusPengecekan.value.hide()
    }

    const hideDialogModalDesc = () => {
      isShowModalDesc.value = false
      valueDescription.value = null
    }

    const hoverDetKet = async (data: any, evt: any) => {
      if (data.StatusPengecekan.CanChange === false) {
        dataChoiceDelivery.value = await data
        refStatusPengecekan.value.toggle(evt)
      }
    }

    const isCanSubmit = async () => {
      // const canSubmit = await dataSource.value.indexOf((obj: any) => obj.StatusPengecekan.CanChange === false)
      const canSubmit = await dataSource.value.map((x: any) => x.StatusPengecekan.CanChange).includes(false)
      return !canSubmit
    }

    const leaveHoverDetKet = (data: any) => {
      if (data.StatusPengecekan.CanChange === false) {
        // dataChoiceDelivery.value = null
        refStatusPengecekan.value.hide()
      }
    }


    const processChangeValRowWithIndex = async (data: any, idPengiriman: any, desc: any) => {
      // console.log('params data => ', data)
      // console.log('params idPengiriman => ', idPengiriman)
      // console.log('desc => ', desc)
      const objIndex = await dataSource.value.findIndex((obj: any) => obj.Id === idPengiriman)
      if (objIndex >= 0) {
        dataSource.value[objIndex].StatusPengecekanId = data.Id
        dataSource.value[objIndex].StatusPengecekan = data
        dataSource.value[objIndex].KeteranganCheckScan = desc
        dataSource.value[objIndex].CheckById = store.getters['appActiveUser/getAppActiveUser']?.id
        dataSource.value[objIndex].NamaChecking = store.getters['appActiveUser/getAppActiveUser']?.name
      }
    }


    const processSubmitCheckResi = async () => {
      const dataSend = {
        Id: dataChoiceDelivery.value.Id,
        StatusPengecekanId: dataChoiceStatus.value.Id,
        KeteranganCheckScan: valueDescription.value
      }
      const response = await receiptCheckingUseCase.editPengecekanResiCheckResi(dataSend)
      if (!response.error) {
        await processChangeValRowWithIndex(dataChoiceStatus.value, dataChoiceDelivery.value.Id, valueDescription.value)
        await $toast.add({
          severity: 'success',
          summary: response.result.title ?? response.result.Title,
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 3000
        })
        hideDialogModalDesc()
        emit('reloadHeader')
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })

      }
    }

    const showDetail = (val: any) => {
      emit('showDetailResi', val)
    }

    const submitData = () => {
      processSubmitCheckResi()
    }

    const cellClick = (data: any) => {
      console.log('cell click', data)
    }
    onMounted(() => {
      getAllTable()
      fetchStatusPengecekanResi()
    })
    return {
      // dummyData
      dataSource,
      autoExpandAll,
      showPanelStatus,
      refStatusPengecekan,
      dataStatusPengecekanResi,
      hoverDetKet,
      leaveHoverDetKet,
      isShowModalDesc,
      valueDescription,
      dataChoiceStatus,
      dataChoiceDelivery,
      choiceStatusFromDropDown,
      hideDialogModalDesc,
      submitData,
      cellClick,
      totalReceived,
      sumTunai,
      sumNonTunai,
      descriptionTableFinished,
      isCanSubmit,
      processChangeValRowWithIndex,
      showDetail,
      dataHeader,
      getAllTable
    }
  }
}
